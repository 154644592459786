/**
 * @Description: 登录页
 * @author GUOGG
 * @date 2019/7/29
 */
import React, {Component} from "react";
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import {LOGIN_URI, REACT_LOGIN_URI} from "../../uri";
import {PROJECT_NAME,COMPANY_NAME_ENGLISH,PROJECT_VERSION} from "../../projectName";
import Axios from "axios";
import Qs from "qs";

import "./Login.css";

class LoginPage extends Component{

    constructor(props){
        super(props);
        this.state = {
            wHeight: '100%',
            loginErr: false,
            loginInfo: '登录账号或登录密码错误'
        };
    }

    componentWillMount(){
        /* 获取登录信息 */
        Axios.get(REACT_LOGIN_URI).then(res => {
            // console.log(res);
            //设置登录 sessionid
            // 已登录时，有sessionid，没登录时，设置为空
            let sessionid = res.data.code === 1000 ? res.data.data.sessionid : "";
            window.sessionStorage.setItem("sessionid", sessionid);
            if(sessionid){
                this.props.history.replace("/");//已登录跳转到首页
            }
        }).catch(error => {
            console.log(error);
        })
    }

    componentDidMount(){
        let wHeight = window.innerHeight;
        this.setState({wHeight})
    }

    /* 登录 */
    handleLogin = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log('Received values of form: ', values);
                let tmp = {
                    username: values.username,
                    password: values.password,
                    reactFlag: 1
                };
                Axios.post(LOGIN_URI, Qs.stringify(tmp)).then(res => {
                    // console.log(res);
                    if(res.data.code === 1000){
                        this.setState({
                            loginErr: false
                        });
                        /* 设置 sessionid */
                        window.sessionStorage.setItem("sessionid", res.data.data.sessionid);
                        this.props.history.replace("/");//登录成功跳转到首页
                    } else {
                        this.setState({
                            loginErr: true,
                            loginInfo: res.data.result
                        })
                    }
                    // if(res.data.message){
                    //     if(res.data.message.indexOf("用户或密码错误") > -1) {
                    //         this.setState({
                    //             loginErr: true
                    //         })
                    //     } else if(res.data.message === "已有用户登陆") {
                    //         this.setState({
                    //             loginErr: false
                    //         });
                    //         window.sessionStorage.setItem("sessionid", res.data.sessionid);
                    //         this.props.history.push("/");//登录成功跳转到首页
                    //     }
                    // } else {
                    //     window.sessionStorage.setItem("sessionid", res.data.sessionid);
                    //     this.props.history.push("/");//登录成功跳转到首页
                    // }
                }).catch(error => {
                    console.log(error);
                })
            }
        });
    };

    handleInputChange = () => {
        /* 隐藏错误信息 */
        this.setState({
            loginErr: false
        })
    };

    render(){
        const year = new Date().getFullYear();
        const { getFieldDecorator } = this.props.form;
        const {wHeight, loginErr, loginInfo} = this.state;
        return(
            <div className="login-wrap" style={{height: wHeight}}>
                <div className="login-main">
                    {/*<h2>果壳租电产品-后台管理系统</h2>*/}
                    <h2>{PROJECT_NAME}</h2>
                    <Form onSubmit={this.handleLogin} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: '请输入用户名' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    size="large"
                                    placeholder="用户名"
                                    onChange={this.handleInputChange}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: '请输入密码' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    size="large"
                                    type="password"
                                    placeholder="密码"
                                    onChange={this.handleInputChange}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(<Checkbox>自动登录</Checkbox>)}
                            {loginErr && <span className="error-text">{loginInfo}</span>}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large" className="login-btn">登录</Button>
                        </Form.Item>
                    </Form>
                    <p className="copyright">Copyright © 2012-{year} Powered By {COMPANY_NAME_ENGLISH} {PROJECT_VERSION}</p>
                </div>
            </div>
        )
    }
}


const Login = Form.create()(LoginPage);
export default Login;
