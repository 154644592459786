/**
 * @Description: store
 * @author GUOGG
 * @date 2019/8/16
 */
import { createStore, applyMiddleware ,compose} from 'redux';
import reducer from './reducers'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
const store = createStore(reducer,composeEnhancers(applyMiddleware(thunk)));

export default store;
