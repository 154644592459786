import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider  } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import {PROJECT_TITLE,PROJECT_TITLE_FAVICON} from "./projectName.js";

moment.locale('zh-cn');


document.title = PROJECT_TITLE;

let publicUrlTmp = process.env.PUBLIC_URL;
let publicUrl = publicUrlTmp.substring(0,publicUrlTmp.length-1);
document.querySelector('link[rel="shortcut icon"]').href=publicUrl+PROJECT_TITLE_FAVICON;


ReactDOM.render(<ConfigProvider locale={zh_CN}><App /></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
