/**
 * @Description: 角色管理 reducers
 * @author GUOGG
 * @date 2019/8/16
 */

import {
    PAGE_PC_CHECKED_VALUES,
    PAGE_MINI_CHECKED_VALUES,
    PAGE_ALLCHECKED_VALUES,
    PAGE_ALLCHECKED_VALUES2,
    FUNCTION_CHECKED_VALUES,
    FUNCTION_ALLCHECKED_VALUES,
    GET_AUTH_CHECK_REQ,
    GET_AUTH_CHECK_REC,
    GET_LEFT_MENU_REQ,
    GET_LEFT_MENU_REC, DataPermissions_CHECKED_VALUES, DataPermissions_ALLCHECKED_VALUES
} from '../actions/RoleAction';


/* PC权限 */
export const setPCPermissionsReducer = (state = [], action) => {
    switch (action.type) {
        case PAGE_PC_CHECKED_VALUES:
            return action.checkedValues;
        case PAGE_ALLCHECKED_VALUES:
            return action.checkedValues;
        default:
            return state;
    }
};

/* 小程序权限 */
export const setMiniPermissionsReducer = (state = [], action) => {
    switch (action.type) {
        case PAGE_MINI_CHECKED_VALUES:
            return action.checkedValues;
        case PAGE_ALLCHECKED_VALUES2:
            return action.checkedValues;
        default:
            return state;
    }
};

/* 功能权限 */
export const setFunctionPermissionsReducer = (state = [], action) => {
    switch (action.type) {
        case FUNCTION_CHECKED_VALUES:
            return action.checkedValues;
        case FUNCTION_ALLCHECKED_VALUES:
            return action.checkedValues;
        default:
            return state;
    }
};

/* 数据权限 */
export const setDataPermissionsReducer = (state = [], action) => {
    switch (action.type) {
        case DataPermissions_CHECKED_VALUES:
            return action.checkedValues;
        case DataPermissions_ALLCHECKED_VALUES:
            return action.checkedValues;
        default:
            return state;
    }
};

/* 功能权限全选 */
// export const setFunctionAllSelectReducer = (state = [], action) => {
//     switch (action.type) {
//         case FUNCTION_ALLCHECKED_VALUES:
//             return action.checkedValues;
//         default:
//             return state;
//     }
// };

/* 所有权限 */
export const getAuthCheckReducer = (state = '', action) => {
    switch (action.type) {
        case GET_AUTH_CHECK_REQ:
            return state;
        case GET_AUTH_CHECK_REC:
            return action.result.data;
        default:
            return state;
    }
};

/* 左侧菜单 */
export const getLeftMenuReducer = (state = [], action) => {
    switch (action.type) {
        case GET_LEFT_MENU_REQ:
            return state;
        case GET_LEFT_MENU_REC:
            return action.result.data;
        default:
            return state;
    }
};
