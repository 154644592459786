/**
 * @Description: 布局
 * @author GUOGG
 * @date 2019/7/16
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Layout, Menu, Badge, Icon, Avatar, Dropdown, notification, Modal} from 'antd';
import PageRoutes from '../routes/routes';
import MenuSide from '../menuSide/MenuSide';
import {DELETE_CABINET_URI, imgUrl, LOGOUT_URI, REACT_LOGIN_URI} from "../uri";
import {PROJECT_TITLE,PROJECT_FAVICON} from "../projectName";
import {COMPANY_NAME,COMPANY_ABBREVIATION,COMPANY_NAME_ENGLISH} from "../projectName";
import Axios from "axios";
import {getAuthCheckAction, getLeftMenuAction} from '../redux/actions/RoleAction';
import {connect} from 'react-redux';
import './layout.css';
import Qs from "qs";
import intl from 'react-intl-universal';
const logo = require('../assets/'+PROJECT_FAVICON);

const {Header, Footer, Sider} = Layout;
const { confirm } = Modal;

class LayoutComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            collapsed: false,
            year: new Date().getFullYear(),
            userName: '',
            userImg: '',
            leftMenu: [],//左侧菜单
        };
    }

    componentDidMount(){

        /* 获取登录信息 */
        Axios.get(REACT_LOGIN_URI).then(res => {
            // console.log(res);
            if(res.data.code === -1){
                window.sessionStorage.setItem("sessionid", "");
                this.props.history.replace('/login');
                return false;
            }
            this.setState({
                userName: res.data.data.name,
                userImg:res.data.data.headImg
            })
        }).catch(error => {
            console.log(error);
        });

        this.handleSetYear();//获取当前年份
        /* 请求菜单 */
        this.props.getLeftMenu();
        /* 请求权限数据 */
        this.props.getAuthCheck();

    }

    handleToggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    };

    /* 获取年份 */
    handleSetYear = () => {
        let date = new Date();
        let year = date.getFullYear();
        this.setState({
            year: year
        })
    };

    /* 退出登录 */
    handleLogout = () => {

        let props = this.props;

        confirm({
            title: intl.get('User_Logout_Confirm_Title'),
            content: intl.get('User_Logout_Confirm_Content'),
            okText: intl.get('User_Logout_Confirm_OkText'),
            okType: 'danger',
            cancelText: intl.get('User_Logout_Confirm_CancelText'),
            centered: true,
            onOk() {
                Axios.get(LOGOUT_URI).then(res => {
                    console.log(res);
                    props.history.replace('/login');
                }).catch(error => {
                    console.log(error);
                    props.history.replace('/login');
                })
            }
        });


    };

    render(){
        const {collapsed, year, userName,userImg} = this.state;

        const userInfoMenu = (
            <Menu>
                <Menu.Item>
                    {/*<Link to="/userInfo" replace>个人信息</Link>*/}
                    <Link to="/userInfo" replace>{intl.get('User_UpdatePassword')}</Link>
                </Menu.Item>
                <Menu.Item onClick={this.handleLogout}>
                    {intl.get('User_Logout')}
                </Menu.Item>
            </Menu>
        );
        return (
            <Layout style={{ height: '100vh' }}>
                <Header className="header">
                    <div className="header-left" style={{width: '500px'}}>
                        <Icon
                            className="trigger"
                            type={collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.handleToggle}
                        />
                        <div className="logo">
                            {/*<span className="logo-img"><img src={logo} alt="HUG CHARGING · BACKSTAGE MANAGEMENT SYSTEM"/></span>*/}
                            <span className="logo-img"><img src={logo} /></span>
                            {/*<span>GOOCKR CHARGING</span>*/}
                            <span>{PROJECT_TITLE}</span>
                        </div>
                    </div>
                    <div className="header-right">
                        {/*<Link to="/changelog" replace><Icon type="question-circle" className="header-icon" /></Link>*/}
                        {/*<Link to="/" replace>*/}
                            {/*<span style={{margin: '0 30px'}}>*/}
                                {/*<Badge count={5}>*/}
                                    {/*<Icon type="bell" className="header-icon" />*/}
                                {/*</Badge>*/}
                            {/*</span>*/}
                        {/*</Link>*/}


                        {/*<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" style={{marginTop: '-7px'}}/>*/}
                        <Avatar src={userImg!=''?imgUrl+userImg:'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'} style={{marginTop: '-7px'}}/>
                        <Dropdown overlay={userInfoMenu}>
                            <span className="ant-dropdown-link">
                                {userName} <Icon type="down" />
                            </span>
                        </Dropdown>
                    </div>
                </Header>
                <Layout>
                    <Sider width={200} style={{background: '#fff'}} trigger={null} collapsible collapsed={collapsed}>
                        <MenuSide collapsed={collapsed} leftMenu={this.props.leftMenu} />
                    </Sider>
                    <Layout id="layout-container">
                        <PageRoutes leftMenu={this.props.leftMenu}/>
                        {/*<Footer style={{ textAlign: 'center' }}>关于果壳 | Copyright © 2016-{year} Iot by Goockr. All Rights Reserved.  佛山市果壳科技 版权所有</Footer>*/}
                        <Footer style={{ textAlign: 'center' }}>{intl.get('about',{"COMPANY_ABBREVIATION":COMPANY_ABBREVIATION,"year":year,"COMPANY_NAME_ENGLISH":COMPANY_NAME_ENGLISH,"COMPANY_NAME":COMPANY_NAME})}</Footer>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        leftMenu: state.getLeftMenuReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        getAuthCheck: () => {
            dispatch(getAuthCheckAction())
        },
        getLeftMenu: () => {
            dispatch(getLeftMenuAction())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
