/**
 * @Description: 资费管理的reducers
 * @author GUOGG
 * @date 2019/8/7
 */

import {
    CHARGING_LINE_FEE_REQ,
    CHARGING_LINE_FEE_REC
} from '../actions/FeeAction';

const lineFeeList = {
    total: 0,
    list: [],
    params: {
        pageNo: 1,
        pageSize: 10,
        orderBy: "updateDate desc"
    }
};

/**
 * @Desc 充电线资费列表
 */
export const getChargingLineFeeReducer = (state = lineFeeList, action) => {
    switch (action.type) {
        case CHARGING_LINE_FEE_REQ:
            return state;
        case CHARGING_LINE_FEE_REC:
            return {total: action.result.data.count, list: action.result.data.list, params: action.props};
        default:
            return state;
    }
};
