/**
 * @Description: 角色管理 action
 * @author GUOGG
 * @date 2019/8/16
 */

/* 页面权限设置 */
import Axios from 'axios';
import {CHECK_ROLE_URI, ROOT_LEFTMENU_URI} from "../../uri";

export const PAGE_PC_CHECKED_VALUES = 'PAGE_PC_CHECKED_VALUES';
export const PAGE_MINI_CHECKED_VALUES = 'PAGE_MINI_CHECKED_VALUES';
export const PAGE_ALLCHECKED_VALUES = 'PAGE_ALLCHECKED_VALUES';
export const PAGE_ALLCHECKED_VALUES2 = 'PAGE_ALLCHECKED_VALUES2';
export const FUNCTION_CHECKED_VALUES = 'FUNCTION_CHECKED_VALUES';
export const FUNCTION_ALLCHECKED_VALUES = 'FUNCTION_ALLCHECKED_VALUES';
export const DataPermissions_CHECKED_VALUES = 'DataPermissions_CHECKED_VALUES';
export const DataPermissions_ALLCHECKED_VALUES = 'DataPermissions_ALLCHECKED_VALUES';
export const GET_AUTH_CHECK_REQ = 'get_auth_check_req';
export const GET_AUTH_CHECK_REC = 'get_auth_check_rec';
export const GET_LEFT_MENU_REQ = 'get_letf_menu_req';
export const GET_LEFT_MENU_REC = 'get_letf_menu_rec';


/**
 * @Desc 请求发起action
 */
const request = (actionType) => {
    return {
        type: actionType
    }
};

/**
 * @Desc 请求成功接受action
 */
const receive = (actionType, params) => {
    return {
        type: actionType,
        result: params.result
    }
};

const receiveValue = (actionType, params) => {
    return {
        type: actionType,
        checkedValues: params
    }
};

/* 设置PC权限 */
export const setPCPermissionsAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(PAGE_PC_CHECKED_VALUES, values))
    }
};

/* 设置小程序权限 */
export const setMiniPermissionsAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(PAGE_MINI_CHECKED_VALUES, values))
    }
};

/* 功能权限 */
export const setFunctionPermissionsAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(FUNCTION_CHECKED_VALUES, values))
    }
};

/* 页面权限全选 pc*/
export const setPageAllSelectAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(PAGE_ALLCHECKED_VALUES, values))
    }
};
/*mini*/
export const setPageAllSelectAction2 = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(PAGE_ALLCHECKED_VALUES2, values))
    }
};

/* 功能权限全选 */
export const setFunctionAllSelectAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(FUNCTION_ALLCHECKED_VALUES, values))
    }
};

/* 数据权限 */
export const setDataPermissionsAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(DataPermissions_CHECKED_VALUES, values))
    }
};

/* 数据权限全选 */
export const setDataAllSelectAction = (values) => {
    return (dispatch) => {
        dispatch(receiveValue(DataPermissions_ALLCHECKED_VALUES, values))
    }
};
/* 获取所  有权限 */
export const getAuthCheckAction = () => {
    return (dispatch) => {
        dispatch(request(GET_AUTH_CHECK_REQ));
        return Axios.get(CHECK_ROLE_URI).then(res => {
            dispatch(receive(GET_AUTH_CHECK_REC, {
                result: res.data
            }))
        })
    }
};

/* 请求左侧菜单 */
export const getLeftMenuAction = () => {
    return(dispatch) => {
        dispatch(request(GET_LEFT_MENU_REQ));
        return Axios.get(ROOT_LEFTMENU_URI).then(res => {
            dispatch(receive(GET_LEFT_MENU_REC, {
                result: res.data
            }))
        })
    }
};



