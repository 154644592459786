/**
 * @Description:
 * @author GUOGG
 * @date 2019/7/19
 */
import React, { Component } from 'react';
import { Spin, notification } from 'antd';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './routes/login/Login';
import LayoutComponent from './layout/layout';
import AuthRoute from './routes/AuthRoute';
import Axios from "axios";
import {Provider} from 'react-redux';
import store from './store';

import './App.css';
import intl from "react-intl-universal";

require('intl/locale-data/jsonp/en.js');
require('intl/locale-data/jsonp/zh.js');
const SUPPOER_LOCALES = [
    {
        name: "English",
        value: "en-US"
    },
    {
        name: "简体中文",
        value: "zh-CN"
    }
];



class App extends Component{

    constructor(props){
        super(props);
        this.state = {
            spinning: false
        };


        const currentLocale = SUPPOER_LOCALES[1].value; // Determine user's locale here
        // const currentLocale = SUPPOER_LOCALES[0].value; // Determine user's locale here
        intl.init({
            currentLocale,
            locales: {
                [currentLocale]: require(`../public/locales/${currentLocale}`)
            }
        });

        // import intl from 'react-intl-universal';
        //{intl.get('')}
        // intl.get('')
        //<span>{intl.get('QueryPage', {total:total, current:current,page:Math.ceil(total/pageSize)})}</span>
    }


    componentDidMount(){
        /* 登陆超时提示 */
        const info = () => {
            notification['warning']({
                key: 'loginTimeout',
                message: <div style={{color: '#FFF'}}>提示</div>,
                description: '登录超时，请重新登录账号',
                duration: 1,
                style: {
                    width: 300,
                    marginTop: -12,
                    marginLeft: 96,
                    backgroundColor: '#29384F',
                    color: '#FFF'
                },
                onClose: () => {
                    window.location.replace(window.location.origin + window.location.pathname + '#/login');
                }
            });
        };

        /* 发送请求前 */
        Axios.interceptors.request.use((request) => {
            // console.log(request);
            this.setState({
                spinning: true
            });
            return request;
        }, (error) => {
            // 对响应错误做处理
            return Promise.reject(error);
        });

        /* 响应拦截器 */
        Axios.interceptors.response.use((response) => {
            /* 未登录 */
            if(response.data.code === -5) {
                info();
            } else if(response.data.code === 5001){
                notification['error']({
                    message: '警告',
                    description: response.data.result
                });
            }

            this.setState({
                spinning: false
            });
            return response;
        }, (error) => {
            // 对响应错误做处理
            // console.log(error.response);
            return Promise.reject(error);
        });

    }

    render() {


        return (
            <Provider store={store}>
                <div className="App">
                    <Spin spinning={this.state.spinning} style={{position: 'fixed', left: '50%', top: '50%', zIndex: 9999999}} />
                    <Router>
                        <Switch>
                            <Route path='/login' component={Login}/>
                            <AuthRoute path='/' component={LayoutComponent}/>
                        </Switch>
                    </Router>
                    {/*<LayoutComponent/>*/}
                </div>
            </Provider>
        );
    }
}

export default App;
