import {service} from './uri';
/**
 * projectName
 * @author xuzhiming
 * @date 2019/10/16
 *
 * 项目的名称
 *
 */

//公司名称
export var COMPANY_NAME = '';
//公司名称简写
export var COMPANY_ABBREVIATION = '';
//公司名称英文
export var COMPANY_NAME_ENGLISH = '';
//后台首页名称
export var PROJECT_NAME = '';
//项目标题
export var PROJECT_TITLE = '';
//项目标题的图标
export var PROJECT_TITLE_FAVICON = 'favicon.ico';
//项目首页的图标
export var PROJECT_FAVICON = 'goockr_logo.png';
//项目版本
export var PROJECT_VERSION = '';
//币种
export var PROJECT_CURRENCY = '元';

//押金设置
export var OPEN_DEPOSIT = true;

switch(service) {
    case 'gk':
        //-------------果壳-----------------
        //公司名称
        COMPANY_NAME = '佛山市果壳科技';
        //公司名称简写
         COMPANY_ABBREVIATION = '果壳';
        //公司名称英文
        COMPANY_NAME_ENGLISH = 'Goockr';
        //后台首页名称
        PROJECT_NAME = '果壳租电产品-后台管理系统';
        //项目标题
        PROJECT_TITLE = 'GOOCKR CHARGING';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'favicon.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'goockr_logo.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = '元';
        //押金设置
        OPEN_DEPOSIT = false;
        break;
    case 'ylc':
        //-------------优立充-----------------
        //公司名称
        COMPANY_NAME = '优立可广告传媒（广州）有限公司';
        //公司名称简写
        COMPANY_ABBREVIATION = '优立可';
        //公司名称英文
        COMPANY_NAME_ENGLISH = 'youlike';
        //后台首页名称
        PROJECT_NAME = '优立充共享线-后台管理系统';
        //项目标题
        PROJECT_TITLE = '优立充共享线-后台管理系统';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'faviconYLC.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'logo_YLC.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = '元';
        //押金设置
        OPEN_DEPOSIT = false;
        break;
    case 'hug':
        //-------------HUG（暂不完善）-----------------
        //公司名称
        COMPANY_NAME = '青岛康士宝电子科技有限公司';
        //公司名称简写
        COMPANY_ABBREVIATION = 'HUG';
        //公司名称英文
        COMPANY_NAME_ENGLISH = 'HUG 4 CHARGE';
        //后台首页名称
        PROJECT_NAME = 'HUG充电宝后台管理系统';
        //项目标题
        PROJECT_TITLE = 'HUG充电宝后台管理系统';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'faviconHUG.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'hug_logo.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = 'C$';
        //押金设置
        OPEN_DEPOSIT = true;
        break;

    case 'CHARGEE':
        //-------------马来西亚-----------------
        //公司名称
        COMPANY_NAME = 'CHARGEE Technology';
        //公司名称简写
        COMPANY_ABBREVIATION = 'CHARGEE';
        //公司名称英文
        COMPANY_NAME_ENGLISH = 'CHARGEE';
        //后台首页名称
        PROJECT_NAME = 'CHARGEE充电宝后台管理系统';
        //项目标题
        PROJECT_TITLE = '充电宝后台管理系统';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'faviconChargee.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'Chargee_logo.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = 'MYR';
        //押金设置
        OPEN_DEPOSIT = true;
        break;

    case 'XF':
        //-------------马来西亚-----------------
        //公司名称
        COMPANY_NAME = '小蜂倍电';
        //公司名称简写
        COMPANY_ABBREVIATION = '小蜂倍电';
        //公司名称英文
        COMPANY_NAME_ENGLISH = '小蜂倍电';
        //后台首页名称
        PROJECT_NAME = '小蜂倍电充电宝后台管理系统';
        //项目标题
        PROJECT_TITLE = '小蜂倍电充电宝后台管理系统';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'faviconBeeinno.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'beeinno_logo.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = '元';
        //押金设置
        OPEN_DEPOSIT = false;
        break;

    case 'Gxxiong':
        //-------------熊盖紧-----------------
        //公司名称
        COMPANY_NAME = '熊盖紧';
        //公司名称简写
        COMPANY_ABBREVIATION = '熊盖紧';
        //公司名称英文
        COMPANY_NAME_ENGLISH = '熊盖紧';
        //后台首页名称
        PROJECT_NAME = '熊盖紧充电宝后台管理系统';
        //项目标题
        PROJECT_TITLE = '熊盖紧充电宝后台管理系统';
        //项目标题的图标
        PROJECT_TITLE_FAVICON = 'faviconGxxiong.ico';
        //项目首页的图标
        PROJECT_FAVICON = 'Gxxiong_logo.png';
        //项目版本
        PROJECT_VERSION = 'V1.0';
        //币种
        PROJECT_CURRENCY = '元';
        //押金设置
        OPEN_DEPOSIT = false;
        break;
}







