/**
 * @Description: 授权路由
 * @author GUOGG
 * @date 2019/7/19
 */
import React from 'react'
import { Route, Redirect, } from 'react-router-dom'

const AuthRoute = ({component: Component, ...rest}) => {
    let sessionId = window.sessionStorage.getItem("sessionid");
    return(
        <Route {...rest} render={(props) => (
            sessionId
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/login',
                    state: {from: props.location}
                }}/>
        )}/>
    )
};

export default AuthRoute
