import React, { Component, Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { /*Route,*/ Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import intl from 'react-intl-universal';

const Home = lazy(() => import('./home/Home'));
const UserInfo = lazy(() => import('./userInfo/UserInfo'));
const CabinetList = lazy(() => import('./cabinetList/CabinetList'));
const PortableBattery = lazy(() => import('./portableBattery/PortableBattery'));
const AgentList = lazy(() => import('./agentList/AgentList'));
const BusinessInfo = lazy(() => import('./businessInfo/BusinessInfo'));
const EndUserList = lazy(() => import('./endUserList/EndUserList'));
const FreeUsersList = lazy(() => import('./FreeUsersList/FreeUsersList'));
const BorrowAndReturnRecords = lazy(() => import('./borrowAndReturnRecords/BorrowAndReturnRecords'));
const TransactionDetails = lazy(() => import('./transactionDetails/TransactionDetails'));
const earningLogList = lazy(() => import('./earningLogList/earningLogList'));
const ErrorAlarmList = lazy(() => import('./errorAlarmList/ErrorAlarmList'));
const ErrorRecord = lazy(() => import('./errorRecord/ErrorRecord'));
const FeeManagement = lazy(() => import('./feeManagement/FeeManagement'));
const RoleManagement = lazy(() => import('./roleManagement/RoleManagement'));
const SharedChargingLine = lazy(() => import('./sharedChargingLine/SharedChargingLine'));
const TransactionStatistics = lazy(() => import('./transactionStatistics/TransactionStatistics'));
const ErrorPage = lazy(() => import('./errorPage/ErrorPage'));
const ChangeLog = lazy(() => import('../components/changeLog/ChangeLog'));
const Peak = lazy(() => import('./peak/peak'));



const allMenu = [
    {id: '9fbba9cd5e244171808257a8e87b75d9',name: intl.get('Menu_Home'), url: '/', component: Home},
    {id: 'fefd13493477420aa6a53024495d726e', name: intl.get('Menu_CabinetList'), url: '/cabinetList', component: CabinetList},
    {id: 'e2314f5f27944301a41fd7460bb6d1ac', name: intl.get('Menu_PortableBattery'), url: '/portableBattery', component: PortableBattery},
    {id: '6a8bf6db71074b9795ed6ef9d594ec76', name: intl.get('Menu_SharedChargingLine'), url: '/sharedChargingLine', component: SharedChargingLine},
    {id: 'e8fa7e4bde8442da832dc632698298d1', name: intl.get('Menu_AgentList'), url: '/agentList', component: AgentList},
    {id: 'eca43ef2a0aa4ad198a0268b6c140d76', name: intl.get('Menu_BusinessInfo'), url: '/businessInfo', component: BusinessInfo},
    {id: '16f18cfefb134d978e9de9babac27ce5', name: intl.get('Menu_EndUserList'), url: '/endUserList', component: EndUserList},
    {id: '16f18cfefb134d978e9de9babac27ce6', name: intl.get('Menu_FreeUsersList'), url: '/freeUsersList', component: FreeUsersList},
    {id: '5470321704ce41a790d9b4d25984644c', name: intl.get('Menu_BorrowAndReturnRecords'), url: '/borrowAndReturnRecords', component: BorrowAndReturnRecords},
    {id: '4a5db7eac6074eadaa1efd0edf0964f8', name: intl.get('Menu_TransactionDetails'), url: '/transactionDetails', component: TransactionDetails},
    {id: '5230fd1d167947bd9be9bc414d520edc', name: intl.get('Menu_TransactionStatistics'), url: '/transactionStatistics', component: TransactionStatistics},
    {id: '5230fd1d167947bd9be9bc414d521edc', name: intl.get('Menu_earningLogList'), url: '/earningLogList', component: earningLogList},
    {id: '05666a2d6c4b4d84a6a0250d1c3a1488', name: intl.get('Menu_ErrorAlarmList'), url: '/errorAlarmList', component: ErrorAlarmList},
    {id: 'b6d4e71b48fc42f8ba7eaccd5ac62613', name: intl.get('Menu_ErrorRecord'), url: '/errorRecord', component: ErrorRecord},
    {id: 'cc798cc1a203409d841048f9664333e5', name: intl.get('Menu_FeeManagement'), url: '/feeManagement', component: FeeManagement},
    {id: 'f32e628f18604d0dbbd389b261c7fa5c', name: intl.get('Menu_RoleManagement'), url: '/roleManagement', component: RoleManagement},
    {id: 'userInfo', name: intl.get('Menu_UserInfo'), url: '/userInfo', component: UserInfo},
    {id: 'updateLog', name: intl.get('Menu_ChangeLog'), url: '/changelog', component: ChangeLog},
    {id: 'peak', name: 'peak', url: '/peak', component: Peak},
    {id: 'errorPage', name: intl.get('Menu_ErrorPage'), component: ErrorPage},
];

class Routes extends Component{

    render(){
        let menuList = [];
        let leftMenu = this.props.leftMenu;
        for(let i = 0; i < allMenu.length; i++){
            for(let j = 0; j < leftMenu.length; j++){
                if(allMenu[i].id === leftMenu[j].id){
                    menuList.push(allMenu[i])
                } else if(allMenu[i].id === 'errorPage' || allMenu[i].id === 'updateLog' || allMenu[i].id === 'userInfo' || allMenu[i].id === 'peak'){
                    menuList.push(allMenu[i])
                }
            }
        }
        return(
            <Suspense fallback={<Spin style={{position: 'fixed', left: '50%', top: '50%', zIndex: 9999999}}/>}>
                <Switch>
                    {/*<AuthRoute exact path="/" component={Home}/>*/}
                    {/*<AuthRoute exact path="/cabinetList" component={CabinetList}/>*/}
                    {/*/!*<AuthRoute exact path="/cabinetList/createCabinet" component={CreateCabinet}/>*!/*/}
                    {/*/!*<AuthRoute exact path="/cabinetList/cabinetDetails" component={CabinetDetails}/>*!/*/}
                    {/*<AuthRoute exact path="/portableBattery" component={PortableBattery}/>*/}
                    {/*<AuthRoute exact path="/agentList" component={AgentList}/>*/}
                    {/*/!*<AuthRoute exact path="/agentList/agentDetails" component={AgentDetails}/>*!/*/}
                    {/*<AuthRoute exact path="/businessInfo" component={BusinessInfo}/>*/}
                    {/*<AuthRoute exact path="/endUserList" component={EndUserList}/>*/}
                    {/*/!*<AuthRoute exact path="/endUserList/userDetails" component={UserDetails}/>*!/*/}
                    {/*<AuthRoute exact path="/borrowAndReturnRecords" component={BorrowAndReturnRecords}/>*/}
                    {/*<AuthRoute exact path="/transactionDetails" component={TransactionDetails}/>*/}
                    {/*<AuthRoute exact path="/errorAlarmList" component={ErrorAlarmList}/>*/}
                    {/*<AuthRoute exact path="/errorRecord" component={ErrorRecord}/>*/}
                    {/*<AuthRoute exact path="/feeManagement" component={FeeManagement}/>*/}
                    {/*/!*<AuthRoute exact path="/feeManagement/createFeeTpl" component={CreateFeeTpl}/>*!/*/}
                    {/*/!*<AuthRoute exact path="/feeManagement/createLineFeeTpl" component={CreateLineFeeTpl}/>*!/*/}
                    {/*/!*<AuthRoute exact path="/feeManagement/feeTplDetails" component={FeeTplDetails}/>*!/*/}
                    {/*/!*<AuthRoute exact path="/feeManagement/chargingLineFeeDetails" component={ChargingLineFeeDetails}/>*!/*/}
                    {/*<AuthRoute exact path="/roleManagement" component={RoleManagement}/>*/}
                    {/*<AuthRoute exact path="/sharedChargingLine" component={SharedChargingLine}/>*/}
                    {/*/!*<AuthRoute exact path="/sharedChargingLine/sharedChargingLineDetails" component={SharedChargingLineDetails}/>*!/*/}
                    {/*/!*<AuthRoute exact path="/sharedChargingLine/createChargingLine" component={CreateChargingLine}/>*!/*/}
                    {/*<AuthRoute exact path="/transactionStatistics" component={TransactionStatistics}/>*/}
                    {/*<AuthRoute exact path="/changelog" component={ChangeLog}/>*/}
                    {/*<AuthRoute component={ErrorPage}/>*/}
                    {/*{sessionid && <Redirect exact from='/login' to='/'/>}*/}

                    {menuList.map(item => (
                        item.id === 'errorPage' ? <AuthRoute component={item.component} key={item.id} /> :
                        <AuthRoute exact path={item.url} component={item.component} key={item.id}/>
                    ))}
                </Switch>
            </Suspense>
        )
    }
}

export default Routes;
