/**
 * @Description: 资费管理的actions
 * @author GUOGG
 * @date 2019/8/7
 */
import Axios from 'axios';
import Qs from 'qs';
import {
    CHARGLINE_FEELIST_URI
} from '../../uri';

export const CHARGING_LINE_FEE_REQ = 'CHARGING_LINE_FEE_REQ';
export const CHARGING_LINE_FEE_REC = 'CHARGING_LINE_FEE_REC';


/**
 * @Desc 请求发起action
 */
const request = (actionType) => {
    return {
        type: actionType
    }
};

/**
 * @Desc 请求成功接受action
 */
const receive = (actionType, params) => {
    return {
        type: actionType,
        result: params.result,
        props: params.props
    }
};

/**
 * @Desc 充电线资费列表
 */
export const getChargingLineFeeAction = (tmp) => {
    return (dispatch) => {
        dispatch(request(CHARGING_LINE_FEE_REQ));
        return Axios.post(CHARGLINE_FEELIST_URI, Qs.stringify(tmp)).then((res) => {
            dispatch(receive(CHARGING_LINE_FEE_REC, {
                result: res.data,
                props: tmp
            }))
        });
    }
};

