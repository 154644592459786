/**
 * @Description: api (杰：(登录、代理商、商户、地区、交易统计、角色管理), 涛：(首页、终端用户、借还记录、交易明细), 明：(机柜、充电宝、充电线、资费管理))
 * @author GUOGG
 * @date 2019/7/22
 */


//选择服务类型
// export const service = 'charge';//本地测试服务
// export const service = 'gk';//果壳服务
export const service = 'ylc';//优力充服务
// export const service = 'hug';//hug服务
// export const service = 'CHARGEE';//马来西亚
// export const service = 'XF';//小峰服务
// export const service = 'Gxxiong';//熊盖紧



var basePath = '/zzcdb';//代理


/* 图片地址 */
export var imgUrl = '/zzcdb';




switch(service) {
    case 'charge':
        basePath = '/zzcdb'; //本地测试服务
        imgUrl = '/zzcdb';//本地测试服务

        // basePath = '/GK'; //本地测试服务
        // imgUrl = '/GK';//本地测试服务
        break;
    case 'gk':
        // basePath = '/zzcdb_test';//果壳线上正式服务
        // imgUrl = '/zzcdb_test';//果壳线上正式
        // basePath = 'https://iot.goockr.com/GK';//果壳线上测试服务
        // imgUrl = 'https://iot.goockr.com/GK';//果壳线上测试
        break;
    case 'ylc':
        basePath = '/ylc';//充电线（优立充）
        imgUrl = '/ylc';//充电线（优立充）
        // basePath = 'https://ylc.ydcharge.com/ylc';//充电线（优立充）
        // imgUrl = 'https://ylc.ydcharge.com/ylc';//充电线（优立充）
        break;
    case 'hug':
        basePath = '/zzcdb';//hug正式服
        imgUrl = '/zzcdb';//hug正式服
        // basePath = 'https://iot.goockr.com/HUG';//HUG测试服（暂未完善）
        // imgUrl = 'https://iot.goockr.com/HUG';//HUG测试服（暂未完善）
        // basePath = '/HUG';//HUG线上测试服务（暂未完善）
        // imgUrl = '/HUG';//HUG线上测试（暂未完善）
        break;
    case 'CHARGEE':
        basePath = '/CHARGEE';//马来西亚正式服
        imgUrl = '/CHARGEE';//马来西亚正式服
        // basePath = 'https://iot.goockr.com/CHARGEE';//马来西亚测试服（暂未完善）
        // imgUrl = 'https://iot.goockr.com/CHARGEE';//马来西亚测试服（暂未完善）
        break;
    case 'XF':
        basePath = '/zzcdbV1';//正式服务
        imgUrl = '/zzcdbV1';//正式
        // basePath = 'https://iot.goockr.com/XF';//小蜂倍电测试服（暂未完善）
        // imgUrl = 'https://iot.goockr.com/XF';//小蜂倍电测试服（暂未完善）
        break;

    case 'Gxxiong':
        basePath = '/zzcdb';//熊盖紧正式服
        imgUrl = '/zzcdb';//熊盖紧
        break;
}


/* 测试服务 */

/* 登录 */
export const LOGIN_URI = basePath + '/a/login';
export const LOGOUT_URI = basePath + '/react/logout';
export const REACT_LOGIN_URI  = basePath + '/react/login';

/*个人信息*/
export const USER_INFODATA_URL = basePath + '/a/nCharge/rUserInfo/infoData';
export const USER_INFO_URL = basePath + '/a/nCharge/rUserInfo/info';
export const USER_PSWD_URL = basePath + '/a/nCharge/rUserInfo/modifyPwd';

/* 首页 */
export const INDEX_AGENT_URI = basePath + '/nCharge/rChargeIndex/agent';
export const INDEX_MERCHANT_URI = basePath + '/nCharge/rChargeIndex/merchant';
export const INDEX_COUNT_URI = basePath + '/nCharge/rChargeIndex/count';
export const INDEX_ONLINEOUDER_URI = basePath + '/nCharge/rChargeIndex/onlineOrder';
export const INDEX_FEEDBACK_URI = basePath + '/nCharge/rChargeIndex/feedback';
export const INDEX_STAT_URI = basePath + '/nCharge/rChargeIndex/stat';
export const INDEX_TNURATIO_URI = basePath + '/nCharge/rChargeIndex/tnuRatio';
// export const INDEX_CBNRATIO_URI = basePath + '/nCharge/rChargeIndex/cbnRatio';
export const INDEX_INDEXSTAT_URI = basePath + '/nCharge/rChargeIndex/indexStat';
export const INDEX_MTRATIO_URI = basePath + '/nCharge/rChargeIndex/mtRatio';
export const INDEX_MAP_URI = basePath + '/nCharge/rChargeIndex/map';
export const INDEX_CABINETSUM_URI = basePath + '/nCharge/rChargeIndex/cabinetSum';

/* 机柜 */
export const CABINET_LIST_URI = basePath + '/nCharge/RChargeCabinet/cabinetList';
export const FORM_CABINET_URI = basePath + '/nCharge/RChargeCabinet/cabinetForm';
export const SAVE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/saveCabinet';
export const BATCHSAVE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/batchSaveCabinet';
export const GET_SHIPPINGSPACE_URI = basePath + '/nCharge/RChargeShippingSpace/getChargeShippingSpace';
export const CLEAR_CABINET_URI = basePath + '/nCharge/RChargeCabinet/devClear';
export const RESET_CABINET_URI = basePath + '/nCharge/RChargeCabinet/reset';
export const DELETE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/deleteCabinet';
export const BATCHDELETE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/batchDeleteCabinet';
export const UPGRADE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/upgrade';
export const DEVIDEXPORT_CABINET_URI = basePath + '/nCharge/RChargeCabinet/exportExcelAfterBatchDevidCabinet';
export const BATCHNUMEXPORT_CABINET_URI = basePath + '/nCharge/RChargeCabinet/exportExcelAfterBatchAddCabinet';
export const DEVONECLEAR_CABINET_URI = basePath + '/nCharge/RChargeCabinet/devOneClear';
export const BINGFEE_CABINET_URI = basePath + '/nCharge/RChargeCabinet/bindingCollectFee';
export const SCANLOG_CABINET_URI = basePath + '/nCharge/RChargeScanLogs/userScanlogList';

//sim卡查询
export const THISMONTHDATA = basePath + '/nCharge/RChargeCabinet/thisMonthData';
export const THISONEDAYDATA = basePath + '/nCharge/RChargeCabinet/thisOneDayData';

/* 充电宝 */
export const LIST_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/list';
export const BINDAGENT_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/chargeBindAgent';
export const UNBINDAGENT_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/chargeUnbindAgent';
export const BINDORUNBINDAGENT_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/chargeBindAgentOrUnbindAgent';
export const DOWNLOADTPL_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/downloadChargeBindAgentOrUnbindAgentTemplate';
export const UNBUY_BATTERY_URI = basePath + '/nCharge/RchargeBaseInfo/cancelPurchase';


/* 充电线 */
export const CHARGINGLINE_LIST_URI = basePath + '/nCharge/RChargeCabinet/chargingCableList';
export const DELETE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/deleteChargingCable';
export const BATCHDELETE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/batchDeleteChargingCable';
export const SAVE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/saveChargingCable';
export const FORM_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/chargingCableForm';
export const BINDAGENT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/bindAgent';
export const UNBINDAGENT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/unbindAgent';
export const BINDMERCHANT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/bindMerchant';
export const UNBINDMERCHANT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/unbindMerchant';
export const BATCHNUMEXPORT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/exportExcelAfterBatchAddChargingCable';
export const DEVIDEXPORT_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/exportExcelAfterBatchDevidChargingCable';
export const BATCHCREATE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/batchSaveChargingCable';
export const RESET_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/chargingCableResetAlgorithm';
export const RESETCODE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/getResetCode';
export const CONFIRMRESETCODE_CHARGINGLINE_URI = basePath + '/nCharge/RChargeCabinet/determineResetCode';

/* 代理商、商户 */
export const AGENT_LIST_URI = basePath + '/react/agent/agentList';
export const AGENT_DETAILS_URI = basePath + '/react/agent/agent';

export const MERCHANT_LIST_URI = basePath + '/react/agent/merchantList';
export const MERCHANT_DETAILS_URI = basePath + '/react/agent/merchant';

export const AGENT_GETLIST_URI = basePath + '/react/agent/getList';
export const BATCHDELETE_GAENT_URI = basePath + '/react/agent/moreDelete';
export const SAVE_DETAILS_URI = basePath + '/react/agent/save';
export const AGENT_UPPERLIST_URI = basePath + '/react/agent/upperlist';
export const DELETE_UPPERLIST_URI = basePath + '/react/agent/delete';
export const BATCHBIND_AGNET_URI = basePath + '/react/agent/setMoreParent';
export const BATCHBIND_ROLE_URI = basePath + '/react/agent/setMoreReactRole';
export const SET_RATIO_URI = basePath + '/react/agent/setMoreEarn';
export const UNBIND_AGENT_URI = basePath + '/react/agent/setMoreUnbind';

export const AGENT_FINDLIST = basePath + '/react/agent/findList';//获取旗下的代理商/商户
export const CREATE_AGENT_READY = basePath + '/react/agent/createReady';
export const GENERATE_QRCODE = basePath + '/nCharge/RchargeMerchantsOperator/GenerateQrCode';//获取商户二维码（增加运营者）
export const MERCHANTS_OPERATOR = basePath + '/nCharge/RchargeMerchantsOperator/list';//获取运营者
export const MERCHANTS_OPERATOR_SETWITHDRAWUSER = basePath + '/nCharge/RchargeMerchantsOperator/setWithdrawUser';//设置运营者的提现的功能
export const MERCHANTS_OPERATOR_CANCELWITHDRAWUSER = basePath + '/nCharge/RchargeMerchantsOperator/cancelWithdrawUser';//取消运营者的提现的功能
export const MERCHANTS_OPERATOR_DELETEOPERATOR = basePath + '/nCharge/RchargeMerchantsOperator/deleteOperator';//取消运营者功能

export const WITHDRAWAL_RECORD_ALLLIST = basePath + '/nCharge/RChargeWithdrawalRecord/allList';//获取提现记录(所有)
export const WITHDRAWAL_RECORD = basePath + '/nCharge/RChargeWithdrawalRecord/list';//获取提现记录(指定商户)
export const AGAIN_WITHDRAWAL = basePath + '/nCharge/RChargeWithdrawalRecord/againWithdrawal';//重新发起提现
export const WITHDRAWAL_SETTRANSFER = basePath + '/nCharge/RChargeWithdrawalRecord/setTransfer';//设置为已转账
export const AGAIN_BATCHWITHDRAWAL = basePath + '/nCharge/RChargeWithdrawalRecord/batchWithdrawal';//批量发起提现

export const FREE_USERS = basePath + '/react/agent/freeUsers';//查看当前的免费用户
export const GENERATE_FREE_USER_QRCODE = basePath + '/react/agent/generateFreeUserQrCode';//获取商户二维码（增加运营者）
export const BATCH_DELETE_FREE_USERS = basePath + '/react/agent/batchDeleteFreeUsers';//删除免费用户

export const GETMONEYRATETIP = basePath + '/react/agent/getMoneyRateTip';//收益比例增加范围提醒


/* 地区 */
export const AREA_URI = basePath + '/react/dict/area';


/* 终端用户 */
export const CTU_LIST_URI = basePath + '/nCharge/rChargeTerminalUser/list';
export const CTU_USERINFO_URI = basePath + '/nCharge/rChargeTerminalUser/info';
export const CTU_MODIFY_URI = basePath + '/nCharge/rChargeTerminalUser/modify';
export const CTU_STAT_URI = basePath + '/nCharge/rChargeTerminalUser/stat';
export const CTU_SCANRECORD_URI = basePath + '/nCharge/rChargeTerminalUser/scanrecord';
export const CTU_RETIREMENT_URI = basePath + '/nCharge/rChargeTerminalUser/manualWithdrawal';
export const CTU_CHECKOUTFREEMERCHANTS_URI = basePath + '/nCharge/rChargeTerminalUser/checkOutFreeMerchants';

/* 免费用户 */
export const CTU_FREEUSERLIST_URI = basePath + '/nCharge/rChargeTerminalUser/freeUsers';


/* 借还记录 */
export const LOAN_RECORD_URI = basePath + '/nCharge/rChargeLoanRecord/list';
export const FINISH_ORDER_URI = basePath + '/nCharge/rChargeLoanRecord/finish';
export const UNFREEZE_ORDER_URI = basePath + '/nCharge/rChargeLoanRecord/unfreeze';

/* 交易明细 */
export const TRANSACT_RECORD_URI = basePath + '/nCharge/rChargeTransactRecord/list';
export const REFUND_RECORD_URI = basePath + '/nCharge/rChargeTransactRecord/refund';

/* 交易统计 */
export const STATICLIST_RECORD_URI = basePath + '/react/count/staticList';
export const STATICMERCHANT_RECORD_URI = basePath + '/react/count/staticMerchant';
export const STATICCABINET_RECORD_URI = basePath + '/react/count/staticCabinet';
export const STATICEXPORT_EXCEL_URI = basePath + '/react/excel/agent';
export const STATICEXPORT_AGENTLIST_URI = basePath + '/react/excel/list';

/* 资费管理 */
export const CHARGLINE_FEELIST_URI = basePath + '/nCharge/RChargeCollectFeesCharging/list';
export const DELETE_LINEFEE_URI = basePath + '/nCharge/RChargeCollectFeesCharging/delete';
export const BATCHDELETE_LINEFEE_URI = basePath + '/nCharge/RChargeCollectFeesCharging/batchDelete';
export const SAVE_LINEFEE_URI = basePath + '/nCharge/RChargeCollectFeesCharging/save';
export const FORM_LINEFEE_URI = basePath + '/nCharge/RChargeCollectFeesCharging/form';
export const CHARGE_FEELIST_URI = basePath + '/nCharge/RChargeCollectFees/list';
export const DELETE_FEELIST_URI = basePath + '/nCharge/RChargeCollectFees/delete';
export const BATCHDELETE_FEELIST_URI = basePath + '/nCharge/RChargeCollectFees/batchDelete';
export const FORM_FEELIST_URI = basePath + '/nCharge/RChargeCollectFees/form';
export const SAVE_FEELIST_URI = basePath + '/nCharge/RChargeCollectFees/save';
//设置充值
export const RECHARGE_SETTING_INFO = basePath + '/nCharge/RSmallroutineRechargeSetting/info';
export const RECHARGE_SETTING_SAVE = basePath + '/nCharge/RSmallroutineRechargeSetting/save';

/* 角色管理 */
export const CHARGE_LIST_URI = basePath + '/react/charge/list';
export const ROOT_LIST_URI = basePath + '/react/root/rootList';
export const ROOT_LEFTMENU_URI = basePath + '/react/root/leftMenu';
export const ROOT_ROLEGET_URI = basePath + '/react/charge/get';
export const ROLE_TYPE_URI = basePath + '/react/charge/pagelist';
export const SAVE_ROLE_URI = basePath + '/react/charge/saveRole';
export const DELETE_ROLE_URI = basePath + '/react/charge/deleteRole';
export const CHECK_ROLE_URI = basePath + '/react/charge/checkShiroWord';
export const MAP_TYPE_URI = basePath + '/react/charge/maptype';

/* 自定义列表 */
export const GET_CUSTOM_LIST = basePath + '/nCharge/reactCustomList/getCustomList';
export const SAVE_CUSTOM_LIST = basePath + '/nCharge/reactCustomList/saveCustomList';

/* 峰值查询 */
export const PEAK_URL = basePath + '/nCharge/rChargeIndex/peak';