import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';

const {SubMenu} = Menu;
/* 菜单列表 */

let menuList;
class  MenuSide extends Component{

    constructor(props) {
        super(props);

        this.state = {
            selectedOpenKeys: [],
            selectedKeys: ''
        };

        this.handleMenuSelected = this.handleMenuSelected.bind(this);

        menuList = [
            {id: '9fbba9cd5e244171808257a8e87b75d9',name: intl.get('Menu_Home'), url: '/', iconType: 'home'},
            {name: intl.get('Menu_DeviceManagement'), key: 'deviceManagement', iconType: 'laptop',
                children: [
                    {id: 'fefd13493477420aa6a53024495d726e', name: intl.get('Menu_CabinetList'), url: '/cabinetList'},
                    {id: 'e2314f5f27944301a41fd7460bb6d1ac', name: intl.get('Menu_PortableBattery'), url: '/portableBattery'},
                    {id: '6a8bf6db71074b9795ed6ef9d594ec76', name: intl.get('Menu_SharedChargingLine'), url: '/sharedChargingLine'}
                ]
            },
            {name: intl.get('Menu_UserManagement'), key: 'userManagement', iconType: 'solution',
                children: [
                    {id: 'e8fa7e4bde8442da832dc632698298d1', name: intl.get('Menu_AgentList'), url: '/agentList'},
                    {id: 'eca43ef2a0aa4ad198a0268b6c140d76', name: intl.get('Menu_BusinessInfo'), url: '/businessInfo'},
                    {id: '16f18cfefb134d978e9de9babac27ce5', name: intl.get('Menu_EndUserList'), url: '/endUserList'},
                    {id: '16f18cfefb134d978e9de9babac27ce6', name: intl.get('Menu_FreeUsersList'), url: '/freeUsersList'}
                ]
            },
            {name: intl.get('Menu_DataStatistics'), key: 'dataStatistics', iconType: 'line-chart',
                children: [
                    {id: '5470321704ce41a790d9b4d25984644c', name: intl.get('Menu_BorrowAndReturnRecords'), url: '/borrowAndReturnRecords'},
                    {id: '4a5db7eac6074eadaa1efd0edf0964f8', name: intl.get('Menu_TransactionDetails'), url: '/transactionDetails'},
                    {id: '5230fd1d167947bd9be9bc414d520edc', name: intl.get('Menu_TransactionStatistics'), url: '/transactionStatistics'},
                    {id: '5230fd1d167947bd9be9bc414d521edc', name: intl.get('Menu_earningLogList'), url: '/earningLogList'},
                ]
            },
            // {name: '系统监控', key: 'systemMonitor', iconType: 'eye',
            //     children: [
            //         {id: '05666a2d6c4b4d84a6a0250d1c3a1488', name: '故障报警', url: '/errorAlarmList'},
            //         {id: 'b6d4e71b48fc42f8ba7eaccd5ac62613', name: '报障记录', url: '/errorRecord'},
            //     ]
            // },
            {name: intl.get('Menu_Config'), key: 'config', iconType: 'exclamation-circle',
                children: [
                    {id: 'cc798cc1a203409d841048f9664333e5', name: intl.get('Menu_FeeManagement'), url: '/feeManagement'},
                    {id: 'f32e628f18604d0dbbd389b261c7fa5c', name: intl.get('Menu_RoleManagement'), url: '/roleManagement'},
                ]
            }
        ];

    }

    componentDidMount(){

        /* 初始化菜单选中状态 */
        let pathname = this.props.location.pathname;
        this.handleMenuSelected(pathname);
        /* 初始化菜单选中状态end */

    }

    /* 过滤菜单 */
    handleFilterMenu = (array) => {
        // console.log(array);
        // let leftMenu = this.props.leftMenu;
        // let menu = [];
        // for(let i = 0; i < array.length; i++){
        //     let obj = {
        //         name: array[i].name,
        //         key: array[i].key,
        //         iconType: array[i].iconType,
        //         children: []
        //     };
        //     let num = 0;
        //     for(let j = 0; j < leftMenu.length; j++){
        //         if(array[i].children){
        //             for(let k = 0; k < array[i].children.length; k++){
        //                 num = 0;
        //                 if(array[i].children[k].id === leftMenu[j].id){
        //                     num += 1;
        //                     obj.children.push({
        //                         id: array[i].children[k].id,
        //                         name: array[i].children[k].name,
        //                         url: array[i].children[k].url
        //                     });
        //                 }
        //                 continue;
        //             }
        //             if(num > 0){
        //                 menu.push(obj);
        //             }
        //         } else {
        //             if(array[i].id === leftMenu[j].id){
        //                 menu.push({
        //                     id: array[i].id,
        //                     name: array[i].name,
        //                     url: array[i].url,
        //                     iconType: array[i].iconType
        //                 });
        //             }
        //         }
        //     }
        // }
        // // console.log(menu);
        // return menu;

        let leftMenu = this.props.leftMenu;
        let menu = [];
        if(leftMenu){
            array.forEach(item => {
                if(item.children && item.children.length){
                    let menuItem = {...item};
                    let child = [];
                    let isAuth = 0;//子菜单有权限时，isAuth大于0
                    item.children.forEach(childItem => {
                        leftMenu.forEach(authItem => {
                            if(authItem.id === childItem.id){
                                isAuth += 1;
                                child.push(childItem);
                            }
                        })
                    });
                    menuItem.children = child;
                    if(isAuth > 0){
                        menu.push(menuItem);
                    }
                } else {
                    leftMenu.forEach(authItem => {
                        if(authItem.id === item.id){
                            menu.push(item);
                        }
                    })
                }
            })
        }
        return menu;
    };

    componentWillReceiveProps(nextProps){
        //菜单展开/收起，有子菜单被选择时，父菜单展开
        if(this.props.collapsed !== nextProps.collapsed){
            menuList.forEach(item => {
                let children = item.children;
                if(children){
                    children.forEach(cItem => {
                        if(cItem.url === this.state.selectedKeys){
                            this.setState({
                                selectedOpenKeys: [item.key]
                            })
                        }
                    })
                }
            })
        }
        //end

        let pathname = nextProps.location.pathname;
        this.handleMenuSelected(pathname);

    }



    /* 菜单状态 */
    handleMenuSelected(key){
        let selectedOpenKeys = null;
        let selectedKeys = null;
        if(key.indexOf('/cabinetList') > -1){//机柜
            selectedKeys = '/cabinetList';
            selectedOpenKeys = ['deviceManagement'];
        } else if(key.indexOf('/portableBattery') > -1){//充电宝
            selectedKeys = '/portableBattery';
            selectedOpenKeys = ['deviceManagement'];
        } else if(key.indexOf('/sharedChargingLine') > -1){//共享充电线
            selectedKeys = '/sharedChargingLine';
            selectedOpenKeys = ['deviceManagement'];
        } else if(key.indexOf('/agentList') > -1){//代理商
            selectedKeys = '/agentList';
            selectedOpenKeys = ['userManagement'];
        } else if(key.indexOf('/businessInfo') > -1){//商户信息
            selectedKeys = '/businessInfo';
            selectedOpenKeys = ['userManagement'];
        } else if(key.indexOf('/endUserList') > -1){//终端用户
            selectedKeys = '/endUserList';
            selectedOpenKeys = ['userManagement'];
        } else if(key.indexOf('/freeUsersList') > -1){//免费用户
            selectedKeys = '/freeUsersList';
            selectedOpenKeys = ['userManagement'];
        } else if(key.indexOf('/borrowAndReturnRecords') > -1){//借还记录
            selectedKeys = '/borrowAndReturnRecords';
            selectedOpenKeys = ['dataStatistics'];
        } else if(key.indexOf('/transactionDetails') > -1){//交易明细
            selectedKeys = '/transactionDetails';
            selectedOpenKeys = ['dataStatistics'];
        } else if(key.indexOf('/transactionStatistics') > -1){//交易统计
            selectedKeys = '/transactionStatistics';
            selectedOpenKeys = ['dataStatistics'];
        } else if(key.indexOf('/earningLogList') > -1){//提现记录
            selectedKeys = '/earningLogList';
            selectedOpenKeys = ['dataStatistics'];
        } else if(key.indexOf('/errorAlarmList') > -1){//故障报警
            selectedKeys = '/errorAlarmList';
            selectedOpenKeys = ['systemMonitor'];
        } else if(key.indexOf('/errorRecord') > -1){//报障记录
            selectedKeys = '/errorRecord';
            selectedOpenKeys = ['systemMonitor'];
        } else if(key.indexOf('/feeManagement') > -1){//资费管理
            selectedKeys = '/feeManagement';
            selectedOpenKeys = ['config'];
        } else if(key.indexOf('/roleManagement') > -1){//角色管理
            selectedKeys = '/roleManagement';
            selectedOpenKeys = ['config'];
        } else {
            selectedKeys = '/';
            selectedOpenKeys = [];
        }
        this.setState({
            selectedKeys,
            selectedOpenKeys
        })
    }

    /* 菜单选择 */
    handleMenuClick = ({ item, key, keyPath, domEvent }) => {
        this.handleMenuSelected(key);
    };

    /* SubMenu 展开/关闭 */
    handleOpenChange = (openKeys) => {
        const latestOpenKey = openKeys.find(key => this.state.selectedOpenKeys.indexOf(key) === -1);
        this.setState({
            selectedOpenKeys : latestOpenKey ? [latestOpenKey] : []
        })

    };

    /* 点击菜单 */
    handleClick = ({ item, key, keyPath, domEvent }) => {
        setTimeout(() =>{
            /* 先跳转到其它路由，再跳转回来，达到重新刷新的效果 */
            this.props.history.replace(key + ' ');
            this.props.history.replace(key);
        })
    };

    render() {
        const { selectedOpenKeys,selectedKeys } = this.state;
        const { collapsed } = this.props;
        const leftMenu = this.handleFilterMenu(menuList);

        return (
            collapsed ? (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['/']}
                    selectedKeys={[selectedKeys]}
                    onClick={this.handleMenuClick}
                    onOpenChange={this.handleOpenChange}
                    style={{height: '100%', borderRight: 0}}
                >
                    {leftMenu.map(item => (
                        item.children ? (
                            <SubMenu
                                key={item.key}
                                title={<span><Icon type={item.iconType} /><span>{item.name}</span></span>}
                                onClick={this.handleClick}
                            >
                                {item.children.map(cItem => (
                                    <Menu.Item key={cItem.url}><Link to={cItem.url} replace>{cItem.name}</Link></Menu.Item>
                                ))}
                            </SubMenu>
                        ) : (
                            <Menu.Item key={item.url} onClick={this.handleClick}>
                                <Link to={item.url} replace>
                                    <Icon type={item.iconType} />
                                    <span>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        )
                    ))}
                </Menu>
            )
            : (
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['/']}
                    openKeys={selectedOpenKeys}
                    selectedKeys={[selectedKeys]}
                    onClick={this.handleMenuClick}
                    onOpenChange={this.handleOpenChange}
                    style={{height: '100%', borderRight: 0}}
                >
                    {leftMenu.map(item => (
                        item.children ? (
                            <SubMenu
                                key={item.key}
                                title={<span><Icon type={item.iconType} /><span>{item.name}</span></span>}
                                onClick={this.handleClick}
                            >
                                {item.children.map(cItem => (
                                    <Menu.Item key={cItem.url}><Link to={cItem.url} replace>{cItem.name}</Link></Menu.Item>
                                ))}
                            </SubMenu>
                        ) : (
                            <Menu.Item key={item.url} onClick={this.handleClick}>
                                <Link to={item.url} replace>
                                    <Icon type={item.iconType} />
                                    <span>{item.name}</span>
                                </Link>
                            </Menu.Item>
                        )
                    ))}
                </Menu>
            )
        )
    }



}

export default withRouter(MenuSide);
