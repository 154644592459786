/**
 * @Description: 组合reducer
 * @author GUOGG
 * @date 2019/8/7
 */

import {combineReducers} from 'redux';
import {getChargingLineFeeReducer} from './redux/reducers/FeeReducer';
import {
    setPCPermissionsReducer,
    setMiniPermissionsReducer,
    setFunctionPermissionsReducer,
    setDataPermissionsReducer,
    getAuthCheckReducer,
    getLeftMenuReducer
} from './redux/reducers/RoleReducer';

const reducers = combineReducers({
    getChargingLineFeeReducer,
    setPCPermissionsReducer,
    setMiniPermissionsReducer,
    setFunctionPermissionsReducer,
    setDataPermissionsReducer,
    getAuthCheckReducer,
    getLeftMenuReducer
});

export default reducers;
